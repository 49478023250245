.footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	font-weight: 300;
	color: #ffffff;
	margin-top: 20px;
  span {
  	width: 79%;
	  height: 1px;
    background: #7B7B7B;
  }
}

.overlay {
	position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.8);
}
.overlay-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
	padding: 40px 50px;
	background: #fff;
  text-align: center;
  &:after {
    content: '';
    width: 104%;
    height: 104.5%;
    position: absolute;
    border: 1px solid #7C7C7C;
    top: -2%;
    left: -2%;
    z-index: -1;
  }
  h3 {
    color: #434343;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  button {
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    border: none;
    background: #ffd306;
    height: 60px;
    transition: .5s;
    &:hover {
      background: #434343;
      color: #ffd306;
    }
  }
}
.overlay-box_close {
	position: absolute;
	top: -25px;
	right: -25px;
	display: block;
	width: 15px;
	height: 15px;
	padding: 3px;
	background: url(img/icon/close.svg) center no-repeat;
	background-size: contain;
  cursor: pointer;
}
.overlay-box_list {
	width: 100%;
	padding-left: 0;
	padding-bottom: 20px;
	padding-top: 20px;
	margin-bottom: 20px;
	border-bottom: 1px solid #B2B2B2;
	border-top: 1px solid #B2B2B2;
  li {
	  display: flex;
	  justify-content: space-between;
	  font-size: 16px;
    font-weight: 700;
    &:first-child {
      margin-bottom: 15px
    }
    span {
      color: #91570b;
      font-weight: 700;
    }
  }
}
.overlay-list_two {
	display: flex;
  margin-left: 30px;
	padding-left: 0;
  margin-bottom: 0;
  li {
    font-weight: 400;
    &:first-child {
      font-weight: 400;
      margin-bottom: 0;
    }
  }
}
.overlay-list_three {
	padding-left: 0;
	margin-bottom: 20px;
  width: 100%;
  li {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 700;
  }
}
.thank {
	position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.thank-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
	padding: 40px 50px;
	background: #f9f9f9;
  text-align: center;
  h3 {
    color: #5a9c04;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 25px;
  }
}
