.background-top {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 285px;
	background: url(img/bgr-top.jpg) center no-repeat;
  background-size: cover;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    border-bottom: 2px dotted #434343;
  }
}
header {
  margin-top: 120px;
}
.header {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
  margin-bottom: 30px;
  h1 {
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    margin-bottom: 0;
  }
  a {
    display: flex;
    align-items: center;
    color: #ffd306;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0.6px;
    transition: .5s;
    &:before {
      content: '';
      display: block;
      width: 42px;
      height: 42px;
      background: url(img/icon/phone.svg) center no-repeat;
      background-size: contain;
      margin-right: 12px;
    }
    &:hover {
      color: #434343;
    }
  }
}
