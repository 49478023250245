.select {
  height: 33px;
  width: 100%;
  font-weight: 500;
  border-radius: 0;
  background: #F6F6F6;
  cursor: pointer;
  border: 1px solid transparent;
  margin-top: 10px;
  font-size: 14px;
  padding: 0 0 0 17px;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 16px;
  user-select: none;
  font-size: 14px;
  color: #000;
  transition: .2s;

  &[aria-selected] {
    cursor: pointer;
  }
}

.select2-container--open {
  .select2-dropdown {
    left: 0;
  }

  .select2-dropdown--above {
    border-bottom: none;
    margin-top: 10px;
  }

  .select2-dropdown--below {
    border-top: none;
  }
}

.select2-search--dropdown {
  display: block;
  padding: 4px;

  .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box;

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }

  &.select2-search--hide {
    display: none;
  }
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity = 0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #ffd306;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-container--default {
  * {
    outline: none;
  }

  .select2-selection--single {
    user-select: none;
    height: 33px;
    width: 100%;
    margin-top: 10px;
    background: #F6F6F6;
    cursor: pointer;
    border: 1px solid transparent;
    display: inline-block;
    position: relative;

    .select2-selection__rendered {
      display: inline-block;
      vertical-align: middle;
      height: 100%;
      font-size: 14px;
      line-height: 30px;
      font-weight: 500;
      color: #000;
      padding-left: 17px;
      padding-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .select2-selection__clear {
      position: relative;
      cursor: pointer;
      float: right;
      font-weight: bold;
    }

    .select2-selection__placeholder {
      color: #999;
    }

    .select2-selection__arrow {
      b {
        position: absolute;
        right: 15px;
        top: 8px;
        width: 15px;
        height: 15px;
        background: url(img/icon/arrow.svg) center no-repeat;
        background-size: contain;
        transition: .2s;
      }
    }
  }

  &[dir="rtl"] .select2-selection--single {
    .select2-selection__rendered {
      padding-right: 8px;
      padding-left: 20px;
    }
    .select2-selection__clear {
      float: left;
    }

    .select2-selection__arrow {
      left: 15px;
      right: auto;
    }
  }

  &.select2-container--disabled .select2-selection--single {
    background-color: #eee;
    cursor: default;

    .select2-selection__clear {
      display: none;
    }
  }

  &.select2-container--open {
    .select2-selection--single .select2-selection__arrow b {
      transform: rotate(180deg);
    }

    & {
      .select2-selection--single, .select2-selection--multiple {
        border: 1px solid #ffd306;
      }
    }
  }

  .select2-search--dropdown .select2-search__field {
    border: 1px solid #ffd306;
  }

  .select2-search--inline .select2-search__field {
    background: transparent;
    border: none;
    outline: 0;
    box-shadow: none;
    -webkit-appearance: textfield;
  }

  .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto;
  }

  .select2-results__option {
    &[role=group] {
      padding: 0;
    }

    &[aria-disabled=true] {
      color: #999;
    }

    &[aria-selected=true] {
      background-color: #ddd;
    }

    .select2-results__option {
      padding-left: 1em;

      .select2-results__group {
        padding-left: 0;
      }

      .select2-results__option {
        margin-left: -1em;
        padding-left: 2em;

        .select2-results__option {
          margin-left: -2em;
          padding-left: 3em;

          .select2-results__option {
            margin-left: -3em;
            padding-left: 4em;

            .select2-results__option {
              margin-left: -4em;
              padding-left: 5em;

              .select2-results__option {
                margin-left: -5em;
                padding-left: 6em;
              }
            }
          }
        }
      }
    }
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color: #ffd306;
    color: white;
  }

  .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
  }
}
