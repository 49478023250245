body {
  font-family: "OpenSans", sans-serif;
}
@import "~bootstrap/scss/bootstrap";

$sl-font-family: Arial, Baskerville, monospace;
$sl-overlay-background: #000000;
$sl-navigation-color: #fff;
$sl-caption-color: #fff;
$sl-caption-background: #000;
$sl-overlay-opacity: 0.8;

$sl-counter-fontsize: 1rem;
$sl-caption-fontsize: 1rem;
$sl-close-fontsize: 3rem;

$sl-breakpoint-medium: 35.5em; // 568px, when 1rem == 16px
$sl-breakpoint-large:  50em;   // 800px, when 1rem == 16px

$sl-arrow-fontsize-small:  2rem;
$sl-arrow-fontsize-medium: 3rem;
$sl-arrow-fontsize-large:  3rem;
$sl-img-border-small:  0 none;
$sl-img-border-medium: 0 none;
$sl-img-border-large:  0 none;
$sl-iframe-border-small:  0 none;
$sl-iframe-border-medium: 0 none;
$sl-iframe-border-large:  0 none;

$add-vendor-prefixes: true !default;

@import "~simplelightbox/dist/simplelightbox";
@import "scss/select2";
@import "scss/reset";
@import "scss/header";
@import "scss/main";
@import "scss/footer";
@import "scss/media";
