@media(max-width: 1200px) {
  .main-tab_text {
    margin-bottom: 20px;
    span {
      margin-bottom: 20px;
      &:after {
        width: 66%;
      }
    }
    h2 {
      font-size: 30px;
    }
  }
  .main-tab_price {
    font-size: 20px;
  }
  .header {
    margin-bottom: 30px;
    h1 {
      font-size: 35px;
    }
  }
  header {
    margin-top: 30px;
  }
  .main-box {
    padding: 30px;
  }
  .background-top {
    height: 200px;
  }
  .footer span {
    width: 74%;
  }
  .main-tab_form label {
    font-size: 13px;
  }
}
@media(max-width: 992px) {
  .col-12 {
    padding: 0;
  }
  header {
    margin-top: 0;
  }
  .background-top {
    height: 210px;
    background: url(img/bgr-top-mob.jpg) center no-repeat;
    background-size: cover;
  }
  .header {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-bottom: 0;
    h1 {
      font-size: 23px;
      margin-bottom: 10px;
    }
  }
  .main-tab_top {
    position: relative;
    flex-direction: column;
  }
  .main-tab_img {
    width: 100%;
    height: 144px;
    margin-bottom: 26px;
  }
  .main-tab_box {
    width: 100%;
  }
  .main-box {
    padding: 25px;
    overflow: hidden;
  }
  .checked-tab {
    flex-wrap: wrap;
    li {
      margin-bottom: 20px;
    }
  }
  .main-tab_text {
    margin-bottom: 10px;
    h2 {
      font-size: 24px;
      font-weight: 600;
      color: #434343;
      text-align: center;
      margin-bottom: 17px;
      width: 100%;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 700;
      color: #b38a47;
      background: $white;
      border: none;
      margin-bottom: 23px;
      &:after {
        content: '';
        width: 150%;
        position: absolute;
        border-bottom: 2px dotted #b38a47;
      }
    }
    b {
      padding-left: 20px;
    }
  }
  .tab-img_pc {
    display: none;
  }
  .tab-img_mob {
    display: block;
  }
  .footer {
    justify-content: center;
    span {
      display: none;
    }
  }
  footer {
    padding-bottom: 50px;
  }
  .main-tab_price {
    justify-content: center;
    padding-bottom: 27px;
    margin-bottom: 27px;
    position: relative;
    &:before {
      position: absolute;
      bottom: 0;
      width: 130%;
      left: -25px;
    }
  }
  .main-tab_bottom {
    margin-top: 20px;
  }
  .main-tab_form {
    label {
      &:nth-child(1) {
        width: 47%;
      }
      &:nth-child(2) {
        width: 47%;
      }
      &:nth-child(3) {
        width: 100%;
        margin-top: 20px;
      }
      &:nth-child(4) {
        width: 100%;
        margin-top: 20px;
      }
    }
    button {
      width: 100%;
      margin-top: 20px;
      height: 46px;
      font-size: 14px;
    }
  }
  .overlay-box {
    padding: 30px 33px;
    h3 {
      font-size: 23px;
      margin-bottom: 17px;
    }
    button {
      height: 46px;
      font-size: 14px;
    }
  }
  .overlay-box_list {
    padding-top: 17px;
    padding-bottom: 17px;
    margin-bottom: 17px;
    li {
      font-size: 14px;
    }
  }
  .overlay-box_close {
    right: -15px;
  }
  .overlay-list_three {
    margin-bottom: 17px;
    li {
      font-size: 14px;
    }
  }
  .thank-box {
    padding: 30px;
    h3 {
      font-size: 21px;
      margin-bottom: 20px;
    }
    p {
      font-size: 14px;
    }
  }
}
