.main-box {
	background: #ffffff;
  position: relative;
  padding: 40px 45px 20px;
}
.main-tab_img {
	width: 36%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.main-tab_mobile {
	display: none;
}
.main-tab_wrap {
  display: flex;
	flex-direction: column;
}
.main-tab_box {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 60%;
}
.main-tab_top {
	display: flex;
	justify-content: space-between;
}
.tab-img_mob {
  display: none;
}
.main-tab_text {
  margin-bottom: 50px;
  h2 {
    color: #434343;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 23px;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    height: 2px;
    border-bottom: 2px dotted #b38a47;
    width: 100%;
  }
  b {
    color: #b38a47;
    font-size: 18px;
    font-weight: 700;
    z-index: 2;
    background: #fff;
    padding-right: 20px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
  }
}
.main-tab_form {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	flex-wrap: wrap;
  label {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 0;
    &:nth-child(1), &:nth-child(2) {
      width: 26%;
    }
    &:nth-child(3) {
      width: 37%;
    }

    &:nth-child(4) {
      width: 57%;
      margin-top: 27px;
    }
  }
  input {
    width: 100%;
    height: 33px;
    font-size: 14px;
    background: #f7f7f7;
    border: 1px solid transparent;
    padding-left: 17px;
    padding-right: 5px;
    margin-top: 10px;
    &::placeholder {
      color: #888;
    }
    &:focus {
      border: 1px solid #ffd306;
    }
  }
  button {
    width: 37%;
    font-size: 18px;
    font-weight: 400;
    background: #ffd306;
    border: none;
    height: 55px;
    transition: .5s;
    &:hover {
      background: #434343;
      color: #ffd306;
    }
  }
}
.main-tab_bottom {
	margin-top: 26px;
}
.main-tab_price {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  color: #000000;
  &:before {
    content: '';
    height: 1px;
    width: 73.5%;
    background: #CCCCCC;
    margin-right: 41px;
  }
  span {
    display: inline-block;
    color: #b38a47;
    margin-left: 15px;
  }
}
.checked-tab {
	display: flex;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
  li {
    font-size: 14px;
    font-weight: 700;
    background: #e4e4e4;
    padding: 5px 18px 5px;
    margin-right: 20px;
    cursor: pointer;
    a {
      color: #000;
    }
    &:last-child {
      margin-right: 0;
    }
    &.active {
      background: #444343;
      a {
        color: #ffd306;
      }
    }
  }
}
.map {
  display: block;
  width: 100%;
  height: 340px;
  position: relative;
  background: url("img/map.jpg") center no-repeat;
  background-size: cover;
}
.docs {
  background: #ffffff;
  position: relative;
  padding: 40px 45px;
  a {
    text-align: center;
    img {

    }
  }
}
