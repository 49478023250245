body {
	line-height: normal;
	background: #434343;
	color: #000000;
	margin: 0;
}
img {
	max-width: 100%;
}
li {
	list-style-type: none;
}
a {
	outline: none;
	text-decoration: none;
  &:hover {
    text-decoration: none;
    outline: none;
  }
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}
textarea {
	resize: none;
  &:hover, &:focus {
    outline: none;
    text-decoration: none;
  }
}
select {
  &:hover, &:focus {
	  outline: none;
    text-decoration: none;
  }
}
input {
  &:hover, &:focus {
	  outline: none;
    text-decoration: none;
  }
}
button {
  &:hover, &:focus {
	  outline: none;
    text-decoration: none;
  }
}
.wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
